export const getharrahsMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-harrahs-route.jpg'),
            mapLine: 'HarrahsPath',
            venueBg:  require('@/assets/maps/harrahs.png'),
            venue: "Harrah's",
            distance: '3',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
