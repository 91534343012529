<template>
  <div id="Harrahs">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getharrahsMapData } from "@/data/sectionData/whereWeAreData/harrahsMapData"

export default {
  name: "Harrahs",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getharrahsMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>
<!-- 4.7miles for Fountainebleau -->
